import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, filter } from 'rxjs';
import { autosuggestActions } from '../actions/autosuggest.actions';
import { HttpService } from '../services/http.service';
import { selectLoaded } from '../selectors/autosuggest.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class AutosuggestEffects {
    getPlaces$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autosuggestActions.getPlaces),
            filter((action) => action.term !== ''),
            concatLatestFrom((action) => this.store.select(selectLoaded(action.term, action.countries))),
            filter(([, loaded]) => loaded === false),
            mergeMap(([action]) =>
                this.httpService.autosuggest(action.term, action.countries).pipe(
                    map((items) =>
                        autosuggestActions.getPlacesSuccess({
                            items,
                            term: action.term,
                            countries: action.countries,
                        }),
                    ),
                    catchError(() =>
                        of(
                            autosuggestActions.getPlacesError({
                                term: action.term,
                                countries: action.countries,
                            }),
                        ),
                    ),
                ),
            ),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private store: Store,
    ) {}
}
