import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { autosuggestClosestActions } from '../actions/autosuggest-closest.actions';
import { selectItem, selectLoading, selectLoadingAnything } from '../selectors/autosuggest-closest.selectors';
import { AutosuggestItem } from '../models/autosuggest.model';
import { GeoPositionModel } from '@app/store/planning/models';

@Injectable({
    providedIn: 'root',
})
export class AutosuggestClosestFacade {
    autosuggestLoading$ = this.store.select(selectLoadingAnything);

    item$ = (street: string, city: string, geoPosition: GeoPositionModel, countries: string[]) => {
        return this.store.select(selectItem(street, city, geoPosition, countries)).pipe(filter((data): data is AutosuggestItem => data != null));
    };

    loading$(street: string, city: string, geoPosition: GeoPositionModel, countries: string[]) {
        return this.store.select(selectLoading(street, city, geoPosition, countries)).pipe(filter((data): data is boolean => data != null));
    }

    constructor(private store: Store) {}

    getClosest(street: string, city: string, geoPosition: GeoPositionModel, selectedCountires: string[]) {
        this.store.dispatch(autosuggestClosestActions.getPlace({ street, city, geoPosition, selectedCountires }));
    }
}
