import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map.component';

@NgModule({
    declarations: [MapComponent],
    imports: [CommonModule],
    exports: [MapComponent],
})
export class Map2Module {}
