import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTOCOMPLETE_KEY } from '../keys';
import { AutocompleteState } from '../reducers/autocomplete.reducer';

export const selectState = createFeatureSelector<AutocompleteState>(AUTOCOMPLETE_KEY);
export const selectAutocompleteItems = createSelector(selectState, (state) => state.data);
export const selectLoading = createSelector(selectState, (state) => {
    return state.data?.filter((item) => item.loading)?.length > 0 || false;
});

export const selectAutocompleteItemsByQuery = (query: string) =>
    createSelector(selectState, (state) => {
        if (!state?.data) {
            return null;
        }
        return state.data?.find((item) => item.query === query)?.items || null;
    });

export const selectAutocompleteLoadingByQuery = (query: string) =>
    createSelector(selectState, (state) => state.data?.find((item) => item.query === query)?.loading || false);
