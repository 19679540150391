import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AutosuggestItem } from '../models/autosuggest.model';
import { GeoPositionModel } from '@app/store/planning/models';

export const autosuggestClosestActions = createActionGroup({
    source: 'HereMaps Closest',
    events: {
        'Get Place': props<{ street: string; city: string; geoPosition: GeoPositionModel; selectedCountires: string[] }>(),
        'Get Place Success': props<{ street: string; city: string; geoPosition: GeoPositionModel; selectedCountires: string[]; item: AutosuggestItem }>(),
        'Get Place Error': props<{ street: string; city: string; geoPosition: GeoPositionModel; selectedCountires: string[] }>(),
        Clear: emptyProps(),
    },
});
