import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { autosuggestActions } from '../actions/autosuggest.actions';
import { selectItems, selectLoading, selectLoadingAnything } from '../selectors/autosuggest.selectors';
import { AutosuggestItem } from '../models/autosuggest.model';

@Injectable({
    providedIn: 'root',
})
export class AutosuggestFacade {
    autosuggestLoading$ = this.store.select(selectLoadingAnything);

    items$(term: string, countries: string[]) {
        return this.store.select(selectItems(term, countries)).pipe(filter((data): data is AutosuggestItem[] => data != null));
    }

    loading$(term: string, countries: string[]) {
        return this.store.select(selectLoading(term, countries));
    }

    constructor(private store: Store) {}

    getAutosuggests(term: string, countries: string[]) {
        this.store.dispatch(autosuggestActions.getPlaces({ term: term.trim(), countries }));
    }
}
