import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../services/map.service';

@Component({
    selector: 'app-map',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapService],
})
export class MapComponent implements AfterViewInit, OnDestroy {
    @ViewChild('map', { static: true }) mapElem!: ElementRef<HTMLDivElement>;
    enableUI = true;

    protected readonly subscriptions$ = new Subscription();

    constructor(public mapService: MapService) {}

    ngAfterViewInit(): void {
        this.mapService.init(this.mapElem, this.enableUI);
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
