import {
    AutosuggestAddressDto,
    AutosuggestHighlightDto,
    AutosuggestHighlightsAddressDto,
    AutosuggestHighlightsDto,
    AutosuggestItemDto,
    AutosuggestPositionDto,
} from './autosuggest.model.dto';

export class AutosuggestItem {
    id: string;
    title: string;
    language: string;
    resultType: string;
    localityType: string;
    address: AutosuggestAddress;
    position: AutosuggestPosition;
    highlights: AutosuggestHighlights | null;

    constructor(data: AutosuggestItemDto) {
        this.id = data.id;
        this.title = data.title;
        this.language = data.language;
        this.resultType = data.resultType;
        this.localityType = data.localityType;
        this.address = new AutosuggestAddress(data.address);
        this.position = new AutosuggestPosition(data.position);
        this.highlights = data.highlights ? new AutosuggestHighlights(data.highlights) : null;
    }
}

export class AutosuggestAddress {
    countryCode: string;
    countryName: string;
    city: string;
    county: string;
    district: string;
    houseNumber: string;
    label: string;
    postalCode: string;
    state: string;
    street: string;

    constructor(data: AutosuggestAddressDto) {
        this.countryCode = data.countryCode || '';
        this.countryName = data.countryName || '';
        this.city = data.city || '';
        this.county = data.county || '';
        this.district = data.district || '';
        this.houseNumber = data.houseNumber || '';
        this.label = data.label || '';
        this.postalCode = data.postalCode || '';
        this.state = data.state || '';
        this.street = data.street || '';
    }
}

export class AutosuggestPosition {
    lat: number | null;
    lng: number | null;

    constructor(data: AutosuggestPositionDto) {
        this.lat = data.lat;
        this.lng = data.lng;
    }
}

export class AutosuggestHighlights {
    address: AutosuggestHighlightsAddress;
    title: AutosuggestHighlight[];
    constructor(data: AutosuggestHighlightsDto) {
        this.address = new AutosuggestHighlightsAddress(data.address);
        this.title = data.title.map((highlight) => new AutosuggestHighlight(highlight));
    }
}

export class AutosuggestHighlightsAddress {
    city: AutosuggestHighlight[];
    county: AutosuggestHighlight[];
    countryName: AutosuggestHighlight[];
    district: AutosuggestHighlight[];
    houseNumber: AutosuggestHighlight[];
    label: AutosuggestHighlight[];
    postalCode: AutosuggestHighlight[];
    state: AutosuggestHighlight[];
    street: AutosuggestHighlight[];
    constructor(data: AutosuggestHighlightsAddressDto) {
        this.city = data.city ? data.city.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.countryName = data.countryName ? data.countryName.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.county = data.county ? data.county.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.district = data.district ? data.district.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.houseNumber = data.houseNumber ? data.houseNumber.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.label = data.label ? data.label.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.postalCode = data.postalCode ? data.postalCode.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.state = data.state ? data.state.map((highlight) => new AutosuggestHighlight(highlight)) : [];
        this.street = data.street ? data.street.map((highlight) => new AutosuggestHighlight(highlight)) : [];
    }
}

export class AutosuggestHighlight {
    start: number;
    end: number;
    constructor(data: AutosuggestHighlightDto) {
        this.start = data.start;
        this.end = data.end;
    }
}
