import { createReducer, on } from '@ngrx/store';
import { AutosuggestItem } from '../models/autosuggest.model';
import { autosuggestClosestActions } from '../actions/autosuggest-closest.actions';
import { GeoPositionModel } from '@app/store/planning/models';

export interface AutossuggestClosestState {
    data: {
        street: string;
        city: string;
        countries: string;
        lat: number | null;
        lng: number | null;
        item: AutosuggestItem | null;
        loading: boolean;
        loaded: boolean;
    }[];
}

export const initialState: AutossuggestClosestState = {
    data: [],
};

export const autosuggestClosestReducer = createReducer(
    initialState,

    on(autosuggestClosestActions.getPlace, (state, payload): AutossuggestClosestState => {
        const index = findIndex(payload, state.data);
        if (index === -1) {
            const data = [...state.data];
            data.push({
                street: payload.street,
                city: payload.city,
                countries: [...payload.selectedCountires].sort().join('|'),
                lat: payload.geoPosition.latitude,
                lng: payload.geoPosition.longitude,
                item: null,
                loading: true,
                loaded: false,
            });
            return { ...state, data };
        }
        return state;
    }),

    on(autosuggestClosestActions.getPlaceSuccess, (state, payload): AutossuggestClosestState => {
        const index = findIndex(payload, state.data);
        if (index !== -1) {
            const data = [...state.data];
            data[index] = {
                ...data[index],
                item: payload.item,
                loading: false,
                loaded: true,
            };
            return { ...state, data };
        }
        return state;
    }),

    on(autosuggestClosestActions.getPlaceError, (state, payload): AutossuggestClosestState => {
        const index = findIndex(payload, state.data);
        if (index !== -1) {
            const data = [...state.data];
            data[index] = {
                ...data[index],
                item: null,
                loading: false,
            };
            return { ...state, data };
        }
        return state;
    }),

    on(
        autosuggestClosestActions.clear,
        (): AutossuggestClosestState => ({
            ...initialState,
        }),
    ),
);

const findIndex = (
    value: { street: string; city: string; geoPosition: GeoPositionModel; selectedCountires: string[] },
    data: {
        street: string;
        city: string;
        countries: string;
        lat: number | null;
        lng: number | null;
        item: AutosuggestItem | null;
        loading: boolean;
    }[],
) => {
    const countries = [...value.selectedCountires].sort().join('|');
    return data.findIndex((item) => {
        return (
            item.street === value.street &&
            item.city === value.city &&
            item.lat === value.geoPosition.latitude &&
            item.lng === value.geoPosition.longitude &&
            item.countries === countries
        );
    });
};
