import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap } from 'rxjs';
import { autocompleteActions } from '../actions/autocomplete.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class AutocompleteEffects {
    getAutocomplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autocompleteActions.getHeremapsAutocompleteAction),
            switchMap((action) =>
                this.httpService.getAutocomplete(action.query).pipe(
                    map((response) =>
                        autocompleteActions.getHeremapsAutocompleteSuccessAction({
                            payload: {
                                items: response,
                                query: action.query,
                            },
                        }),
                    ),
                    catchError(() => of(autocompleteActions.getHeremapsAutocompleteErrorAction())),
                ),
            ),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
