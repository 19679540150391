import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, filter } from 'rxjs';
import { autosuggestClosestActions } from '../actions/autosuggest-closest.actions';
import { HttpService } from '../services/http.service';
import { selectLoaded } from '../selectors/autosuggest-closest.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class AutosuggestClosestEffects {
    getPlaces$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autosuggestClosestActions.getPlace),
            concatLatestFrom((action) => this.store.select(selectLoaded(action.street, action.city, action.geoPosition, action.selectedCountires))),
            filter(([, loaded]) => loaded === false),
            mergeMap(([action]) =>
                this.httpService.closest(action.street, action.city, action.geoPosition.latitude, action.geoPosition.longitude, action.selectedCountires).pipe(
                    map((item) =>
                        autosuggestClosestActions.getPlaceSuccess({
                            street: action.street,
                            city: action.city,
                            geoPosition: action.geoPosition,
                            selectedCountires: action.selectedCountires,
                            item: item,
                        }),
                    ),
                    catchError(() =>
                        of(
                            autosuggestClosestActions.getPlaceError({
                                street: action.street,
                                city: action.city,
                                geoPosition: action.geoPosition,
                                selectedCountires: action.selectedCountires,
                            }),
                        ),
                    ),
                ),
            ),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private store: Store,
    ) {}
}
