import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { autocompleteActions } from '../actions/autocomplete.actions';
import { AutocompleteItemModel } from '../models';
import { selectAutocompleteItemsByQuery, selectLoading } from '../selectors/autocomplete.selector';

@Injectable({
    providedIn: 'root',
})
export class AutocompleteFacade {
    constructor(private store: Store) {}

    getAutocomplete(query: string) {
        this.store.dispatch(autocompleteActions.getHeremapsAutocompleteAction({ query }));
    }

    loading$(): Observable<boolean> {
        return this.store.select(selectLoading);
    }

    items$(query: string) {
        return this.store.select(selectAutocompleteItemsByQuery(query)).pipe(filter((data): data is AutocompleteItemModel[] => data != null));
    }
}
