import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTOSUGGEST_KEY } from '../keys';
import { AutossuggestState } from '../reducers/autosuggest.reducers';

const selectState = createFeatureSelector<AutossuggestState>(AUTOSUGGEST_KEY);
const selectItems = (term: string, countries: string[]) => {
    const countriesSorted = [...countries].sort().join('|');
    const key = term;

    return createSelector(selectState, (state) => state.data.find((data) => data.key === key && data.countries === countriesSorted)?.items || null);
};

const selectLoading = (term: string, countries: string[]) => {
    const countriesSorted = [...countries].sort().join('|');
    const key = term;

    return createSelector(selectState, (state) => state.data.find((data) => data.key === key && data.countries === countriesSorted)?.loading || false);
};

const selectLoaded = (term: string, countries: string[]) => {
    const countriesSorted = [...countries].sort().join('|');
    const key = term;

    return createSelector(selectState, (state) => state.data.find((data) => data.key === key && data.countries === countriesSorted)?.loaded || false);
};

const selectLoadingAnything = createSelector(selectState, (state) => state.data.findIndex((data) => data.loading === true) !== -1);

export { selectItems, selectLoading, selectLoaded, selectLoadingAnything };
