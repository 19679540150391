import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { AutocompleteItemModel, AutocompleteModel } from '../models/autocomplete.model';
import { AutosuggestItem } from '../models/autosuggest.model';
import { AutosuggestResponseDto } from '../models/autosuggest.model.dto';
import { GeoPosition } from '../interfaces/selected-address.interface';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getAutocomplete(query: string): Observable<AutocompleteItemModel[]> {
        return this.httpClient
            .get<ApiResponse<AutocompleteModel>>(`proxy/here-maps/autocomplete/autocomplete?q=${query}`)
            .pipe(map((response) => response.data.items));
    }

    getAddress(query: GeoPosition): Observable<AutocompleteItemModel> {
        return this.httpClient
            .get<ApiResponse<AutocompleteModel>>(`proxy/here-maps/revgeocode/revgeocode?at=${query.latitude}%2C${query.longitude}`)
            .pipe(map((response) => response.data.items[0]));
    }

    autosuggest(query: string, countries: string[]): Observable<AutosuggestItem[]> {
        const params: { [key: string]: string } = {
            q: query,
            limit: '100',
            show: 'details',
            at: environment.integrations.hereMaps.autosuggest.center.join(','),
        };

        if (countries.length > 0) {
            params['in'] = `countryCode:${countries.join(',')}`;
        }

        return this.httpClient
            .skipErrorHandler()
            .get<AutosuggestResponseDto>('here-maps/proxy/autosuggest/autosuggest', { params })
            .pipe(
                map((response) =>
                    response.items
                        .filter((item) => ['locality', 'street', 'houseNumber'].includes(item.resultType))
                        .splice(0, 5)
                        .map((item) => new AutosuggestItem(item)),
                ),
            );
    }

    closest(street: string, city: string, lat: number | null, lng: number | null, countries: string[]): Observable<AutosuggestItem> {
        const params: { [key: string]: string } = {
            q: street,
            limit: '100',
            show: 'details',
            at: [lat, lng].join(','),
        };

        if (countries.length > 0) {
            params['in'] = `countryCode:${countries.join(',')}`;
        }

        return this.httpClient
            .skipErrorHandler()
            .get<AutosuggestResponseDto>('here-maps/proxy/autosuggest/autosuggest', { params })
            .pipe(
                mergeMap((response) => {
                    const items = [
                        ...response.items.filter((item) => ['street', 'houseNumber'].includes(item.resultType)).filter((item) => item.address.city === city),
                    ];
                    if (items.length === 0) {
                        return this.geocode(<number>lat, <number>lng);
                    }
                    if (items[0].address.postalCode) {
                        return of(new AutosuggestItem(items[0]));
                    }
                    if (!items[0].address.postalCode) {
                        return this.geocode(items[0].position.lat, items[0].position.lng);
                    }
                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }

    geocode(lat: number, lng: number): Observable<AutosuggestItem> {
        const params: { [key: string]: string } = {
            limit: '100',
            at: `${lat},${lng}`,
        };

        return this.httpClient
            .skipErrorHandler()
            .get<AutosuggestResponseDto>('here-maps/proxy/revgeocode/revgeocode', { params })
            .pipe(
                map((response) => {
                    const items = response.items.filter((item) => item.resultType === 'houseNumber' && item.address.postalCode);
                    if (items.length > 0) {
                        return new AutosuggestItem(items[0]);
                    }
                    throw new HttpErrorResponse({ error: 'not-found', status: 404 });
                }),
            );
    }
}
