import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTOSUGGEST_CLOSEST_KEY } from '../keys';
import { AutossuggestClosestState } from '../reducers/autosuggest-closest.reducers';
import { GeoPositionModel } from '@app/store/planning/models';
import { AutosuggestItem } from '../models';

const selectState = createFeatureSelector<AutossuggestClosestState>(AUTOSUGGEST_CLOSEST_KEY);

const selectItem = (street: string, city: string, geoPosition: GeoPositionModel, selectedCountires: string[]) =>
    createSelector(selectState, (state) => find({ street, city, geoPosition, selectedCountires }, state.data)?.item ?? null);

const selectLoading = (street: string, city: string, geoPosition: GeoPositionModel, selectedCountires: string[]) =>
    createSelector(selectState, (state) => find({ street, city, geoPosition, selectedCountires }, state.data)?.loading ?? null);

const selectLoaded = (street: string, city: string, geoPosition: GeoPositionModel, selectedCountires: string[]) =>
    createSelector(selectState, (state) => find({ street, city, geoPosition, selectedCountires }, state.data)?.loaded ?? false);

const selectLoadingAnything = createSelector(selectState, (state) => state.data.findIndex((data) => data.loading === true) !== -1);

const find = (
    value: { street: string; city: string; geoPosition: GeoPositionModel; selectedCountires: string[] },
    data: {
        street: string;
        city: string;
        countries: string;
        lat: number | null;
        lng: number | null;
        item: AutosuggestItem | null;
        loading: boolean;
        loaded: boolean;
    }[],
) => {
    const countries = [...value.selectedCountires].sort().join('|');
    return data.find(
        (item) =>
            item.street === value.street &&
            item.city === value.city &&
            item.lat === value.geoPosition.latitude &&
            item.lng === value.geoPosition.longitude &&
            item.countries === countries,
    );
};

export { selectItem, selectLoading, selectLoaded, selectLoadingAnything };
