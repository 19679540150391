import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AUTOCOMPLETE_KEY, AUTOSUGGEST_CLOSEST_KEY, AUTOSUGGEST_KEY } from './keys';
import { autocompleteReducer } from './reducers/autocomplete.reducer';
import { autosuggestReducer } from './reducers/autosuggest.reducers';
import { autosuggestClosestReducer } from './reducers/autosuggest-closest.reducers';
import { AutocompleteEffects } from './effects/autocomplete.effect';
import { AutosuggestEffects } from './effects/autosuggest.effects';
import { AutosuggestClosestEffects } from './effects/autosuggest-closest.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(AUTOCOMPLETE_KEY, autocompleteReducer),
        StoreModule.forFeature(AUTOSUGGEST_KEY, autosuggestReducer),
        StoreModule.forFeature(AUTOSUGGEST_CLOSEST_KEY, autosuggestClosestReducer),
        EffectsModule.forFeature([AutocompleteEffects, AutosuggestEffects, AutosuggestClosestEffects]),
    ],
})
export class HeremapsModule {}
