import { ElementRef, OnDestroy, Injectable } from '@angular/core';
import H from '@here/maps-api-for-javascript';
import { environment } from '@env/environment';
import { debounceTime, fromEvent, Subscription, tap } from 'rxjs';

interface DefaultLayer extends GlobalObject {
    vector: {
        normal: {
            map: H.map.layer.Layer;
        };
    };
}

@Injectable()
export class MapService implements OnDestroy {
    H_MAP!: H.Map;
    H_MAP_BEHAVIOR!: H.mapevents.Behavior;
    H_MAP_UI!: H.ui.UI;
    H_MAP_PLATFORM = new H.service.Platform({
        apikey: environment.integrations.hereMaps.apiKey,
    });

    element!: ElementRef<HTMLElement>;

    private windowResize$ = fromEvent(window, 'resize').pipe(
        debounceTime(100),
        tap(() => this.H_MAP.getViewPort().resize()),
    );

    readonly subscriptions$ = new Subscription();

    init(element: ElementRef<HTMLElement>, enableUI = true): void {
        this.element = element;
        const defaultLayers = <DefaultLayer>this.H_MAP_PLATFORM.createDefaultLayers();

        this.H_MAP = new H.Map(element.nativeElement, defaultLayers.vector.normal.map, {
            zoom: environment.config.hereMaps.initialZoom,
            center: { lat: environment.config.hereMaps.initialLat, lng: environment.config.hereMaps.initilaLng },
            pixelRatio: window.devicePixelRatio || 1,
            engineType: H.Map.EngineType.WEBGL,
        });

        this.H_MAP_BEHAVIOR = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.H_MAP));
        this.H_MAP_BEHAVIOR.disable(H.mapevents.Behavior.Feature.DBL_TAP_ZOOM);
        if (enableUI) {
            this.H_MAP_UI = H.ui.UI.createDefault(this.H_MAP, defaultLayers, environment.config.hereMaps.uiLanguage);
        }
        this.subscriptions$.add(this.windowResize$.subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    disableWheelZoom() {
        this.H_MAP_BEHAVIOR.disable(H.mapevents.Behavior.Feature.WHEEL_ZOOM);
    }
}
