import { createAction, props } from '@ngrx/store';
import { QueryAddress } from '../interfaces/selected-address.interface';

export enum AutocompleteActionTypes {
    GET_HEREMAPS_AUTOCOMPLETE_ACTION = '[Heremaps - Autocomplete] Get autocomplete items action',
    GET_HEREMAPS_AUTOCOMPLETE_SUCCESS_ACTION = '[Heremaps - Autocomplete] Get autocomplete items success action',
    GET_HEREMAPS_AUTOCOMPLETE_ERROR_ACTION = '[Heremaps - Autocomplete] Get autocomplete items error action',
}

const getHeremapsAutocompleteAction = createAction(AutocompleteActionTypes.GET_HEREMAPS_AUTOCOMPLETE_ACTION, props<{ query: string }>());
const getHeremapsAutocompleteSuccessAction = createAction(AutocompleteActionTypes.GET_HEREMAPS_AUTOCOMPLETE_SUCCESS_ACTION, props<{ payload: QueryAddress }>());
const getHeremapsAutocompleteErrorAction = createAction(AutocompleteActionTypes.GET_HEREMAPS_AUTOCOMPLETE_ERROR_ACTION);

export const autocompleteActions = {
    getHeremapsAutocompleteAction,
    getHeremapsAutocompleteSuccessAction,
    getHeremapsAutocompleteErrorAction,
};
