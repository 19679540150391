import { createReducer, on } from '@ngrx/store';
import { autocompleteActions } from '../actions/autocomplete.actions';
import { QueryAddress } from '../interfaces/selected-address.interface';

export const AUTOCOMPLETE_FEATURE_KEY = 'autocomplete';

export interface AutocompleteState {
    data: AutocompleteQueryStateData[];
}

export interface AutocompleteQueryStateData extends QueryAddress {
    loading: boolean;
}

export const initialState: AutocompleteState = {
    data: [],
};

export const autocompleteReducer = createReducer(
    initialState,
    on(autocompleteActions.getHeremapsAutocompleteAction, (state, action): AutocompleteState => {
        const queryIndex = state.data.findIndex((item) => item.query === action.query);

        if (queryIndex !== -1) {
            const item = {
                ...state.data[queryIndex],
                loading: true,
            };

            const newData = [...state.data];

            newData[queryIndex] = item;

            return {
                ...state,
                data: newData,
            };
        }

        return {
            ...state,
            data: [...(state.data || []), { query: action.query, items: null, loading: true }],
        };
    }),
    on(autocompleteActions.getHeremapsAutocompleteSuccessAction, (state, action): AutocompleteState => {
        const queryIndex = state.data.findIndex((item) => item.query === action.payload.query);
        const newData = [...state.data];

        newData[queryIndex] = {
            items: action.payload.items || [],
            query: action.payload.query || '',
            loading: false,
        };

        return {
            ...state,
            data: newData,
        };
    }),
    on(
        autocompleteActions.getHeremapsAutocompleteErrorAction,
        (state): AutocompleteState => ({
            ...state,
        }),
    ),
);
