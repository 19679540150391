import { createReducer, on } from '@ngrx/store';
import { AutosuggestItem } from '../models/autosuggest.model';
import { autosuggestActions } from '../actions/autosuggest.actions';

export interface AutossuggestState {
    data: {
        key: string;
        countries: string;
        items: AutosuggestItem[] | null;
        loading: boolean;
        loaded: boolean;
    }[];
}

export const initialState: AutossuggestState = {
    data: [],
};

export const autosuggestReducer = createReducer(
    initialState,

    on(autosuggestActions.getPlaces, (state, payload): AutossuggestState => {
        const countries = [...payload.countries].sort().join('|');
        const key = payload.term;
        if (payload.term !== '') {
            const index = state.data.findIndex((data) => data.key === key && data.countries === countries);
            if (index === -1) {
                const data = [...state.data];
                data.push({
                    key,
                    countries,
                    items: null,
                    loading: true,
                    loaded: false,
                });
                return {
                    ...state,
                    data,
                };
            }
        }
        return state;
    }),

    on(autosuggestActions.getPlacesSuccess, (state, payload): AutossuggestState => {
        const countries = [...payload.countries].sort().join('|');
        const key = payload.term;
        const index = state.data.findIndex((data) => data.key === key && data.countries === countries);
        if (index !== -1) {
            const data = [...state.data];
            data[index] = {
                ...data[index],
                items: payload.items,
                loading: false,
                loaded: true,
            };
            return {
                ...state,
                data,
            };
        }
        return state;
    }),

    on(autosuggestActions.getPlacesError, (state, payload): AutossuggestState => {
        const countries = [...payload.countries].sort().join('|');
        const key = payload.term;
        const index = state.data.findIndex((data) => data.key === key && data.countries === countries);
        if (index !== -1) {
            const data = [...state.data];
            data[index] = {
                ...data[index],
                items: [],
                loading: false,
            };
            return {
                ...state,
                data,
            };
        }
        return state;
    }),

    on(
        autosuggestActions.clear,
        (): AutossuggestState => ({
            ...initialState,
        }),
    ),
);
