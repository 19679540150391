import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AutosuggestItem } from '../models/autosuggest.model';

export const autosuggestActions = createActionGroup({
    source: 'HereMaps Autosuggest',
    events: {
        'Get Places': props<{ term: string; countries: string[] }>(),
        'Get Places Success': props<{ term: string; countries: string[]; items: AutosuggestItem[] }>(),
        'Get Places Error': props<{ term: string; countries: string[] }>(),
        Clear: emptyProps(),
    },
});
